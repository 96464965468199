import { environment } from '../../environments/environment';
import {AddIndividual,
  ApplicationTypes,
  CheckUserVerificationCodePayload, CheckVerificationCodePayload, DigitalIdAuthenticationPayload, DigitalIdClientInfoPayload,
  GetApplicationindividualsPayload,
  Individual, IndividualWithResult, ParsedDigitalIdResponse,
  SendAskForVerificationInfoEmailPayload,
  SendVerificationSucceededNotificationEmailPayload,
  SetupApplicationIndividualMapping,
  UpdateAndKycVerifyUserPayload,
  UpdateApplicationIndividualDigitalIdMappingInput,
  UpdateApplicationIndividualDigitalIdMappingPayload,
  UpdateApplicationIndividualInfoPayload,
  UpdateIndividualData,
  UpdateIndividualPayload, VerifyApplicationIndividualsPayload, VerifyOneApplicationIndividualPayload } from '@portal-workspace/grow-shared-library';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DigitalIdAuthenticateFn, DigitalIdGetClientIdFn, DigitalIdUpdateIndividualFn, httpOptions} from '@portal-workspace/grow-ui-library';
import { PayloadApiResponse } from '@portal-workspace/grow-shared-library';
import { map } from 'rxjs/operators';

const apiHost = environment.api2Host;
const URL_AUTHENTICATE = (type: 'basic' | 'premium') => `${apiHost}/api2/digitalId/authenticate/${type}`;
const URL_CLIENT_INFO =  ()=> `${apiHost}/api2/digitalId/clientInfo`;
const URL_CHECK_USER_VERIFICATION_CODE = (verificationCode: string) => `${apiHost}/api2/digitalId/checkUserVerificationCode/${verificationCode}`;
const URL_CHECK_VERIFICATION_CODE = (verificationCode: string) => `${apiHost}/api2/digitalId/checkVerificationCode/${verificationCode}`;
const URL_VERIFY_INDIVIDUALS = (applicationId: number) => `${apiHost}/api2/digitalId/verifyApplicationIndividuals/${applicationId}`;
const URL_UPDATE_AND_KYC_VERIFY_USER = (userId: number) => `${apiHost}/api2/digitalId/updateAndKycVerifyUser/${userId}`;
const URL_UPDATE_AND_VERIFY_INDIVIDUAL_INFO = (applicationId: number, individualId: string) => `${apiHost}/api2/digitalId/updateAndVerifyApplicationIndividualInfo/${applicationId}/${individualId}`;
const URL_GET_APPLICATION_INDIVIDUALS = (applicationId: number) => `${apiHost}/api2/digitalId/getApplicationIndividuals/${applicationId}`;
const URL_SEND_ASK_FOR_VERIFICATION_INFO_EMAIL = (applicationId: number, individualId: string) =>  `${apiHost}/api2/digitalId/sendAskForVerificationInfoEmail/${applicationId}/${individualId}`;
const URL_VERIFY_ONE_APPLICATION_INDIVIDUAL = (applicationId: number, individualId: string) =>  `${apiHost}/api2/digitalId/verifyOneApplicationIndividual/${applicationId}/${individualId}`;
const URL_SETUP_APPLICATION_INDIVIDUALS_MAPPING = (applicationId: number) => `${apiHost}/api2/digitalId/setupApplicationIndividualsMapping/${applicationId}`;
const URL_UPDATE_INDIVIDUAL = (applicationId: number, individualId: string, applicationType: ApplicationTypes) => `${apiHost}/api2/digitalId/updateIndividual/${applicationId}/${individualId}/${applicationType}`;
const URL_UPDATE_PRIMARY_CONTACT = (applicationId: number, individualId: string) => `${apiHost}/api2/digitalId/updatePrimaryContact/${applicationId}/${individualId}`;
const URL_ADD_INDIVIDUAL = (applicationId: number) => `${apiHost}/api2/digitalId/addIndividual/${applicationId}`;
const URL_UPDATE_APPLICATION_INDIVIDUAL_DIGITAL_ID_MAPPING = (applicationId: number, individualId: string) => `${apiHost}/api2/digitalId/updateApplicationIndividualDigitalIdMapping/${applicationId}/${individualId}`;
const URL_SEND_VERIFICATION_SUCCEEDED_NOTIFICATION_EMAIL = (applicationId: number, individualId: string) => `${apiHost}/api2/digitalId/sendVerificationSucceededNotificationEmail/${applicationId}/${individualId}`;
const URL_PRINT_DIGITAL_ID_RESULT_PDF = (applicationId: number, individualId: string) => `${apiHost}/api2/digitalId/generateDigitalIdResultPdf/${applicationId}/${individualId}`;


@Injectable()
export class DigitalIdService {

  constructor(private httpClient: HttpClient) { }

  authenticateFn: DigitalIdAuthenticateFn = (data: {code: string, transaction_id: string}, type: 'basic' | 'premium') => {
    return this.authenticate(data, type);
  }
  authenticate(data: { code: string, transaction_id: string }, type: 'basic' | 'premium'): Observable<DigitalIdAuthenticationPayload> {
    return this.httpClient
      .post<PayloadApiResponse<DigitalIdAuthenticationPayload>>(
        URL_AUTHENTICATE(type), data, httpOptions())
      .pipe(map(r => r.payload));
  }

  clientIdFn: DigitalIdGetClientIdFn = (token?: string) => {
    return this.clientId(token);
  }
  clientId(token?: string): Observable<DigitalIdClientInfoPayload> {
    console.log('token', token)
    return this.httpClient
      .get<PayloadApiResponse<DigitalIdClientInfoPayload>>(URL_CLIENT_INFO(), !token ? httpOptions() : {
        ...httpOptions(),
        headers: {
          ...httpOptions().headers,
          'Authorization': `Bearer ${token}`
        }
      })
      .pipe(map(r => r.payload));
  }


  checkUserVerificationCode(verificationCode: string): Observable<PayloadApiResponse<CheckUserVerificationCodePayload>> {
    return this.httpClient.get<PayloadApiResponse<CheckUserVerificationCodePayload>>(
      URL_CHECK_USER_VERIFICATION_CODE(verificationCode), httpOptions());
  }
  checkVerificationCode(verificationCode: string): Observable<PayloadApiResponse<CheckVerificationCodePayload>> {
    return this.httpClient.get<PayloadApiResponse<CheckVerificationCodePayload>>(
      URL_CHECK_VERIFICATION_CODE(verificationCode), httpOptions());
  }

  // updateIndividualFn = (applicationId: number, individualId: string, data: ParsedDigitalIdResponse & { mobileNumber: string, email: string, guarantor: boolean, deleted: boolean }) => {
  updateIndividualFn: DigitalIdUpdateIndividualFn = (applicationId: number, individualId: string, applicationType: ApplicationTypes, data: UpdateIndividualData) => {
    return this.updateIndividual(applicationId, individualId, applicationType, data).pipe(map(r => r.payload));
  }
  // updateIndividual(applicationId: number, individualId: string, data: ParsedDigitalIdResponse & { mobileNumber: string, email: string, guarantor: boolean, deleted: boolean }):
  updateIndividual(applicationId: number, individualId: string, applicationType: ApplicationTypes, data: UpdateIndividualData):
    Observable<PayloadApiResponse<UpdateIndividualPayload>> {
    return this.httpClient.post<PayloadApiResponse<UpdateIndividualPayload>>(
      URL_UPDATE_INDIVIDUAL(applicationId, individualId, applicationType), data, httpOptions());
  }

  updatePrimaryContact(applicationId: number, individualId: string|undefined):
    Observable<PayloadApiResponse<UpdateIndividualPayload>>{
      return this.httpClient.post<PayloadApiResponse<UpdateIndividualPayload>>(
      URL_UPDATE_PRIMARY_CONTACT(applicationId, individualId!), httpOptions());
  }

  verifyApplicationIndividualsFn = (applicationId: number, sendEmail: boolean) => {
    return this.verifyApplicationIndividuals(applicationId, sendEmail).pipe(map(r => r.payload));
  }
  verifyApplicationIndividuals(applicationId: number, sendEmail: boolean = false):
    Observable<PayloadApiResponse<VerifyApplicationIndividualsPayload>> {
    return this.httpClient.get<PayloadApiResponse<VerifyApplicationIndividualsPayload>>(
      URL_VERIFY_INDIVIDUALS(applicationId), {
        ...httpOptions(),
        params: {
          sendEmail: sendEmail ? 'true' : 'false'
        }
      }
    );
  }

  updateAndKycVerifyUser = (userId: number, payload: {verificationCode: string, digitalIdAuthenticationPayload: DigitalIdAuthenticationPayload}) => {
    return this.httpClient.post<PayloadApiResponse<UpdateAndKycVerifyUserPayload>>(
      URL_UPDATE_AND_KYC_VERIFY_USER(userId), payload, httpOptions()
    )
  }

  updateApplicationIndividualInfoFn = (applicationId: number, individualId: string, parsedDigitalIdResponse: UpdateIndividualData) => {
    return this.updateApplicationIndividualInfo(applicationId, individualId, parsedDigitalIdResponse).pipe(map(r => r.payload));
  }
  updateApplicationIndividualInfo(applicationId: number, individualId: string, parsedDigitalIdResponse: UpdateIndividualData):
    Observable<PayloadApiResponse<UpdateApplicationIndividualInfoPayload>> {
    return this.httpClient.post<PayloadApiResponse<UpdateApplicationIndividualInfoPayload>>(
      URL_UPDATE_AND_VERIFY_INDIVIDUAL_INFO(applicationId, individualId),
      parsedDigitalIdResponse, httpOptions());
  }

  updateApplicationIndividualDigitalIdMappingFn = (applicationId: number, individualId: string, input: UpdateApplicationIndividualDigitalIdMappingInput) => {
    return this.updateApplicationIndividualDigitalIdMapping(applicationId, individualId, input).pipe(map(r => r.payload));
  }
  updateApplicationIndividualDigitalIdMapping(applicationId: number, individualId: string,
                                              input: UpdateApplicationIndividualDigitalIdMappingInput):
    Observable<PayloadApiResponse<UpdateApplicationIndividualDigitalIdMappingPayload>> {
    return this.httpClient.post<PayloadApiResponse<UpdateApplicationIndividualDigitalIdMappingPayload>>(
      URL_UPDATE_APPLICATION_INDIVIDUAL_DIGITAL_ID_MAPPING(applicationId, individualId), input, httpOptions()
    );
  }

  sendVerificationSucceededNotificationEmail(applicationId: number, individualId: string):
    Observable<PayloadApiResponse<SendVerificationSucceededNotificationEmailPayload>>{
    return this.httpClient.get<PayloadApiResponse<SendVerificationSucceededNotificationEmailPayload>>(
      URL_SEND_VERIFICATION_SUCCEEDED_NOTIFICATION_EMAIL(applicationId, individualId), httpOptions());
  }

  getApplicationIndividualsFn = (applicationId: number) => {
    return this.getApplicationIndividuals(applicationId).pipe(map(r => r.payload));
  }
  getApplicationIndividuals(applicationId: number): Observable<PayloadApiResponse<GetApplicationindividualsPayload>> {
    return this.httpClient.get<PayloadApiResponse<GetApplicationindividualsPayload>>(
      URL_GET_APPLICATION_INDIVIDUALS(applicationId),
      {
        ...httpOptions(),
        params: {
          t: String(Math.random())
        }
      });
  }

  sendAskForVerificationInfoEmailFn = (applicationId: number, individualId: string) => {
    return this.sendAskForVerificationInfoEmail(applicationId, individualId).pipe(map(r => r.payload));
  }
  sendAskForVerificationInfoEmail(applicationId: number, individualId: string):
    Observable<PayloadApiResponse<SendAskForVerificationInfoEmailPayload>> {
    return this.httpClient.get<PayloadApiResponse<SendAskForVerificationInfoEmailPayload>>(
      URL_SEND_ASK_FOR_VERIFICATION_INFO_EMAIL(applicationId, individualId), httpOptions());
  }

  verifyOneApplicationIndividualFn = (applicationId: number, individualId: string) => {
    return this.verifyOneApplicationIndividual(applicationId, individualId).pipe(map(r => r.payload));
  }
  verifyOneApplicationIndividual(applicationId: number, individualId: string):
    Observable<PayloadApiResponse<VerifyOneApplicationIndividualPayload>> {
    return this.httpClient.get<PayloadApiResponse<VerifyOneApplicationIndividualPayload>>(
      URL_VERIFY_ONE_APPLICATION_INDIVIDUAL(applicationId, individualId), httpOptions());
  }

  setupApplicationIndividualMappingFn = (applicationId: number) => {
    return this.setupApplicationIndividualMapping(applicationId).pipe(map(r => r.payload));
  }
  setupApplicationIndividualMapping(applicationId: number):
    Observable<PayloadApiResponse<SetupApplicationIndividualMapping>> {
    return this.httpClient.get<PayloadApiResponse<SetupApplicationIndividualMapping>>(
      URL_SETUP_APPLICATION_INDIVIDUALS_MAPPING(applicationId), httpOptions());
  }

  addIndividualFn = (applicationId: number, data: AddIndividual) => {
    return this.addIndividual(applicationId, data).pipe(
      map(r => r.payload)
    );
  }
  addIndividual(applicationId: number, data: AddIndividual): Observable<PayloadApiResponse<Individual>> {
    return this.httpClient.post<PayloadApiResponse<Individual>>(
      URL_ADD_INDIVIDUAL(applicationId), data, httpOptions());
  }

  printDigitalIdResultFn = (applicationId: number, individualId: string) => {
    return this.printDigitalIdResult(applicationId, individualId);
  }
  printDigitalIdResult(applicationId: number, individualId: string): Observable<Blob> {
    return this.httpClient.request(
      'get',
      URL_PRINT_DIGITAL_ID_RESULT_PDF(applicationId, individualId),
      {
        ...httpOptions(),
        observe: 'body',
        responseType: 'blob'
      });
  }


  // use @portal-workspace/grow-ui-library  -> parseDigitalIdResponse instead
  // parseDigitalIdResponse(e: DigitalIdComponentEvent): ParsedDigitalIdResponse {
  //   const r: DigitalIdAuthenticationPayload = (e.authResult as any).payload;
  //   const address: string = r.individualInfo.address.formatted.replace('\n', ' ');
  //   const parsedAddress = parseRawAddress(address);
  //   return {
  //     birthdate: r.individualInfo.birthdate,
  //     firstName: r.individualInfo.given_name,
  //     middleName: r.individualInfo.middle_name,
  //     lastName: r.individualInfo.family_name,
  //     country: r.individualInfo.address.country,
  //     state: r.individualInfo.address.region,
  //     postcode: r.individualInfo.address.postal_code,
  //     suburb: r.individualInfo.address.locality,
  //     rawAddress: r.individualInfo.address.formatted.replace('\n', ' '),
  //     streetName: parsedAddress && parsedAddress.street_name ? parsedAddress.street_name : '',
  //     streetNumber: parsedAddress && parsedAddress.street_number ? parsedAddress.street_number : '',
  //     streetType: parsedAddress && parsedAddress.street_type ? parsedAddress.street_type : '',
  //     unitNumber: parsedAddress && parsedAddress.unit_number ? parsedAddress.unit_number : '',
  //   };
  // };
}

