<div class="individual-form-dialog container-fluid">
  <form [formGroup]="formGroup">
    <div class="row pt-4">
      <div class="col">
        <h3 class="subheading-2">Add new individual</h3>
      </div>
    </div>
    <div class="row separator-bottom">
      <div class="col">
        <mat-checkbox class="mr-1" color="primary" [formControl]="formControlIsThirdPartyEntity"></mat-checkbox>
        <span>Third party entity</span>
      </div>
    </div>
    @if (formControlIsThirdPartyEntity.value) {
      <div class="row">
        <div class="col-9">
          <business-number-search
            [formControl]="formControlThirdPartyEntity"
            [searchFn]="data.businessSearchFn">
          </business-number-search>
        </div>
      </div>
    }

    <div class="row">
      <div class="col-4">
        <title-selection  [formControl]="formControlTitle"></title-selection>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <name title="First Name" [formControl]="formControlFirstName"></name>
      </div>
      <div class="col-4">
        <mat-form-field>
          <mat-label>Middle name</mat-label>
          <input matInput type="text" [formControl]="formControlMiddleName">
        </mat-form-field>
      </div>
      <div class="col-4">
        <name title="Last Name" [formControl]="formControlLastName"></name>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <datepicker  title="Date of birth" [allowOnly18YrsPlus]="true"
        [formControl]="formControlDob"></datepicker>
      </div>
      <div class="col-4">
        <gender [formControl]="formControlGender"></gender>
      </div>
    </div>

    <div class="row">
      <div class="col-9">
        <custom-address  title="Residential address" [formControl]="formControlResidentialAddress"></custom-address>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <mat-form-field>
          <mat-label>Role</mat-label>
          <mat-select [formControl]="formControlSignerRole">
            <mat-option [value]="'Signer'">Signer</mat-option>
            <mat-option [value]="'Guarantor'">Guarantor</mat-option>
            <mat-option [value]="'GuarantorSigner'">Guarantor / Signer</mat-option>
            <mat-option [value]="'Others'">Others</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- @if(formControlSignerRole.value === 'Others' ){
      <div class="col-4">
        <mat-form-field>
          <mat-label>Contact</mat-label>
          <mat-select [formControl]="formControlPrimaryContact">
            <mat-option [value]="'Contact'">Contact</mat-option>
            <mat-option [value]="'PrimaryContact'">Primary Contact</mat-option>
          </mat-select>
        </mat-form-field>
      </div> 
    } -->
      <!--
      <div class="col-4">
        <yes-no title="Guarantor" [formControl]="formControlGuarantor"></yes-no>
      </div>
      -->
    </div>


    <div class="row">
      <div class="col-6">
        <email [mandatory]="mandatoryField" (events)="onEmailEvent($event)" [validators]="[duplicateEmailValidator]" [formControl]="formControlEmail" ></email>
      </div>
      <div class="col-6">
        <mobile [mandatory]="mandatoryField" (events)="onMobileEvent($event)" [validators]="[duplicateMobileValidator]" [formControl]="formControlMobile"  title="Mobile"></mobile>
      </div>
    </div>

    <div class="row pb-4">
      <div class="col">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <button mat-stroked-button (click)="onCancel()">Cancel</button>
          <button mat-flat-button [disabled]="disableSubmission()" (click)="onSubmit()">Add Individual</button>
        </div>
      </div>
    </div>

  </form>
</div>
