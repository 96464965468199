<div class="primary-contact-details-component">
  @if (!contact) {
    <div class="no-entries">
      <div fxLayout="column" fxLayoutAlign="center center">
        <img src="/assets/images/faq-empty.png">
        <div class="mat-h3 title">No entries</div>
      </div>
    </div>
  }
  @if (contact) {
    <div class="contact">
      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Primary contact</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Title</div>
            <div body>{{contact.Title}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>First name</div>
            <div body><p [innerHTML]="contact.GivenName | ellipsis:10"></p></div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Last name</div>
            <div body><p [innerHTML]="contact.SurName | ellipsis:10"></p></div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Mobile number</div>
            
            <div body><!--span>+61</span-->{{contact.Mobile}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Telephone</div>
            <div body><!--span>+61</span-->{{contact.Telephone}}</div>
            
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <!-- <div label>Area code</div>
            <div body>{{contact.AreaCode}}</div> -->
            <div label>Email</div>
            <div body>{{contact.Email}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <!-- <div class="row">
      <div class="col-lg-6 col-md-12">
        <cell>
          <div label>Email</div>
          <div body>{{contact.Email}}</div>
        </cell>
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider> -->
  </div>
}
</div>
