import {Component, Input, OnInit} from '@angular/core';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, concat, Observable, Subscription} from 'rxjs';
import {
  AddIndividual,
  Address2ComponentValue,
  Application,
  DatepickerValue,
  DigitalIdGetApplicationIndividualsFn,
  DigitalIdResult,
  EmailComponentValue,
  getApplicationStatus,
  Individual,
  IndividualFormDialogResult,
  IndividualWithResult,
  MobileValue,
  NameComponentValue,
  OriginatorBusiness,
  ParsedDigitalIdResponse,
  parseRawAddress,
  SendAskForVerificationInfoEmailPayload,
  SetupApplicationIndividualMapping,
  SignerRoleTypes,
  TitleSelectionValue,
  UpdateApplicationIndividualDigitalIdMappingInput,
  UpdateApplicationIndividualDigitalIdMappingPayload,
  UpdateApplicationIndividualInfoPayload,
  UpdateIndividualData,
  UpdateIndividualPayload,
  User,
  VerifyApplicationIndividualsPayload,
  VerifyOneApplicationIndividualPayload,
  isInternalUser,
  BusinessNumberSearchValue,
  IndividualAdditionalRole,
  isAnalyst,
  DigitalIdComponentEvent,
  DownloadDocumentFromAzureFn,
  BoundingBoxData,
  SendIdVerifyLinkFn,
  BypassFaceCompareFn,
  DeleteIdentityVerificationFn,
  SendPrivacyConsentEmailFn,
  Address,
  getApplicationCustomerName,
  ApplicationTypes,
  ConfirmationDialogResult
} from '@portal-workspace/grow-shared-library';
import {animate, state, style, transition, trigger } from "@angular/animations";
import {tap} from 'rxjs/operators';
import {
  ApplicationDialogService,
  DigitalIdAuthenticateFn,
  DigitalIdGetClientIdFn,
  createEmailInputMask,
  createPhoneNumberInputMask,
  individualDob,
  individualResidentialAddress,
  individualTitle,
  PortalHotToastService,
  setupUntilDestroy,
  parseDigitalIdResponse, getUser, BusinessSearchFn,
  BusinessNumberSearchFn, duplicateIndividualEmailValidator, duplicateIndividualMobileValidator, MobileComponentEvent, EmailComponentEvent, applicationToApplicantFirstName, applicationToApplicantLastName,
} from '@portal-workspace/grow-ui-library';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {HotToastService} from '@ngneat/hot-toast';
import {loadingFor} from '@ngneat/loadoff';
import _ from 'lodash';
import { AddressSplitPipe } from '../../pipes/address-split.pipe';
import { SanitizeHtmlPipe } from '../../pipes/sanitize-html.pipe';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { TagBoxComponent } from '../message-box/tag-box.component';
import { DigitalIdComponent } from '../digital-id-component/digital-id.component';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { MobileComponent } from '../mobile-component/mobile.component';
import { EmailComponent } from '../common fields/email.component';
import { CustomAddressComponent } from '../address-component/custom-address.component';
import { DatepickerComponent } from '../datepicker-component/datepicker.component';
import { TitleSelectionComponent } from '../title-selection-component/title-selection.component';
import { NameComponent } from '../name-component/name.component';
import { DisableControlDirective } from '../../directives/disable-control.directive';
import { BusinessNumberSearchComponent } from '../business-number-search-component/business-number-search.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, NgStyle, DatePipe, JsonPipe } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatDividerModule } from '@angular/material/divider';
import { DomSanitizer } from '@angular/platform-browser';
import { Buffer } from 'buffer';
import mime from 'mime';
import { SignerRolePipe } from '../../pipes/signer-role.pipe';
import { ImageWithBoundingBoxComponent } from '../image-component/image-with-bounding-box.component';
import {AddressPipe} from "../../pipes/address.pipe";
import { PrivacyConsentStatusChipComponent } from '../privacy-consent-status-component/privacy-consent-status-chip.component';
import { DigitalIdService } from '../../../../../../apps/portal2/src/app/service/digital-id.service';

export type KycVerificationIndividualFormGroup = FormGroup<{
    title: FormControl<TitleSelectionValue>,
    firstName: FormControl<NameComponentValue>,
    middleName: FormControl<string|null>,
    lastName: FormControl<NameComponentValue>,
    dob: FormControl<DatepickerValue>,
    residentialAddress: FormControl<Address2ComponentValue>,
    email: FormControl<EmailComponentValue>,
    mobile: FormControl<MobileValue>,
    signerRole: FormControl<SignerRoleTypes>,
    additionalRoles: FormArray<AdditionalRoleFormGroup>,
    contact: FormControl<string | null>
  }>;
export type KycVerificationIndividualsFormArray = FormArray<KycVerificationIndividualFormGroup>;
export type KycVerificationPossibleFormControls =
  FormControl<TitleSelectionValue> |
  FormControl<NameComponentValue> |
  FormControl<string|null> |
  FormControl<NameComponentValue> |
  FormControl<DatepickerValue> |
  FormControl<Address2ComponentValue> |
  FormControl<EmailComponentValue> |
  FormControl<MobileValue> |
  FormControl<string | null>;
export type AdditionalRoleFormGroup = FormGroup<{
  business: FormControl<BusinessNumberSearchValue>,
  signerRole: FormControl<SignerRoleTypes>,
}>

export type KycVerificationIndividualFormGroupKeys = 'title' | 'firstName' | 'middleName' | 'lastName' | 'dob' | 'residentialAddress' | 'email' | 'mobile' | 'signerRole' | 'contact';

type IndividualInfo = {formGroup: KycVerificationIndividualFormGroup, individual: IndividualWithResult};

export class KycVerificationIndividualsDataSource extends DataSource<IndividualWithResult> {
  subject = new BehaviorSubject<IndividualWithResult[]>([]);
  connect(collectionViewer: CollectionViewer): Observable<IndividualWithResult[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(_data: IndividualWithResult[]) {
    // only show those that are not deleted
    const data = _data.filter(i => {
      return i.deleted !== true;
    });
    this.subject.next(data);
  }
}



export type GetOriginatorByIdFn = (originatorBusinessId: number) => Observable<OriginatorBusiness | null>;
export type DigitalIdAddIndividualFn = (applicationId: number, data: AddIndividual) => Observable<Individual>;
export type DigitalIdVerifyApplicationIndividualsFn = (applicationId: number, sendEmail: boolean) => Observable<VerifyApplicationIndividualsPayload>;
export type DigitalIdVerifyOneApplicationIndividualFn = (applicationId: number, individualId: string) => Observable<VerifyOneApplicationIndividualPayload>;
export type DigitalIdSendAskForVerificationInfoEmailFn = (applicationId: number, individualId: string) => Observable<SendAskForVerificationInfoEmailPayload>;
// export type DigitalIdGetApplicationIndividualsFn = (applicationId: number) => Observable<GetApplicationindividualsPayload>;
// export type DigitalIdUpdateIndividualFn = (applicationId: number, individualId: string, data: ParsedDigitalIdResponse & {mobileNumber: string, email: string, guarantor: boolean, deleted: boolean}) => Observable<UpdateIndividualPayload>;
export type DigitalIdUpdateIndividualFn = (applicationId: number, individualId: string, applicationType: ApplicationTypes, data: UpdateIndividualData) => Observable<UpdateIndividualPayload>;
export type DigitalIdUpdateApplicationIndividualDigitalIdMappingFn = (applicationId: number, individualId: string, input: UpdateApplicationIndividualDigitalIdMappingInput) => Observable<UpdateApplicationIndividualDigitalIdMappingPayload>;
export type DigitalIdUpdateApplicationIndividualInfoFn = (applicationId: number, individualId: string, parsedDigitalIdResponse: ParsedDigitalIdResponse) => Observable<UpdateApplicationIndividualInfoPayload>;
export type DigitalIdPrintDigitalIdResultFn = (applicationId: number, individualId: string) => Observable<Blob>;
export type DigitalIdSetupApplicationIndividualMappingFn = (applicationId: number) => Observable<SetupApplicationIndividualMapping>;

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'kyc-verification',
  templateUrl: './kyc-verification.component.html',
  styleUrls: ['./kyc-verification.component.scss'],
  animations: [
      trigger('detailExpand', [
          state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
          state('expanded', style({ height: '*', display: 'block' })),
          transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
  ],
  standalone: true,
  imports: [
    FlexModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDividerModule,
    MatCardModule,
    MatTableModule,
    MatTooltipModule,
    MatMenuModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    SignerRolePipe,
    ReactiveFormsModule,
    MatOptionModule,
    BusinessNumberSearchComponent,
    DisableControlDirective,
    NameComponent,
    TitleSelectionComponent,
    DatepickerComponent,
    CustomAddressComponent,
    EmailComponent,
    MobileComponent,
    MessageBoxComponent,
    DigitalIdComponent,
    TagBoxComponent,
    NgClass,
    ExtendedModule,
    NgStyle,
    DatePipe,
    TruncatePipe,
    SanitizeHtmlPipe,
    AddressSplitPipe,
    ImageWithBoundingBoxComponent,
    AddressPipe,
    JsonPipe,
    PrivacyConsentStatusChipComponent,
]
})
export class KycVerificationComponent implements OnInit {

  loader = loadingFor('allIndividualsDigitalIdCheck', 'singleIndividualDigitalIdCheck', 'deleteIndividual');

  dataSource = new KycVerificationIndividualsDataSource();
  columnsToDisplay = ['dir', 'firstName', 'dob', 'address', 'signerRole', 'additionalRole', 'status', 'action'];

  expandedElement: IndividualWithResult | null = null; // row element (IndividualWithResult) which is expanded
  expandedResultElement: IndividualWithResult | null = null; // row result element (IndividualWithResult) which is expanded;

  isInternalUser = isInternalUser;
  isAnalyst = isAnalyst;
  getApplicationCustomerName = getApplicationCustomerName;
  // isExternalUser = isExternalUser;

  isBrokerDisclosed = false;

  subscriptions: Subscription[] = [];
  loggedInUser: User | null = getUser();

  @Input({required: true}) getOriginatorByIdFn!: GetOriginatorByIdFn;
  @Input({required: true}) businessSearchFn!: BusinessSearchFn;
  @Input({required: true}) addIndividualFn!: DigitalIdAddIndividualFn;
  @Input({required: true}) verifyApplicationIndividualsFn!: DigitalIdVerifyApplicationIndividualsFn;
  @Input({required: true}) verifyOneApplicationIndividualFn!: DigitalIdVerifyOneApplicationIndividualFn;
  @Input({required: true}) sendAskForVerificationInfoEmailFn!: DigitalIdSendAskForVerificationInfoEmailFn;
  @Input({required: true}) getApplicationIndividualsFn!: DigitalIdGetApplicationIndividualsFn;
  @Input({required: true}) updateIndividualFn!: DigitalIdUpdateIndividualFn;
  @Input({required: true}) updateApplicationIndividualDigitalIdMappingFn!: DigitalIdUpdateApplicationIndividualDigitalIdMappingFn;
  @Input({required: true}) updateApplicationIndividualInfoFn!: DigitalIdUpdateApplicationIndividualInfoFn;
  @Input({required: true}) printDigitalIdResultFn!: DigitalIdPrintDigitalIdResultFn;
  @Input({required: true}) setupApplicationIndividualMappingFn!: DigitalIdSetupApplicationIndividualMappingFn;
  @Input({required: true}) businessNumberSearchFn!: BusinessNumberSearchFn;
  @Input({required: true}) sendPrivacyConsentEmailFn!: SendPrivacyConsentEmailFn;
  @Input({required: true}) idVerifyUrl!: string;
  @Input({required: true}) application!: Application;

  @Input({required: true}) getClientFn!: DigitalIdGetClientIdFn;
  @Input({required: true}) authenticateFn!: DigitalIdAuthenticateFn;
  @Input({required: true}) downloadDocumentFromAzureFn!: DownloadDocumentFromAzureFn;
  @Input({required: true}) sendIdVerifyLinkFn!: SendIdVerifyLinkFn;
  @Input({required: true}) bypassFaceCompareFn!: BypassFaceCompareFn;
  @Input({required: true}) deleteIdentityVerificationFn!: DeleteIdentityVerificationFn;

  alerts:{type: 'warn', message: string}[] = [];

  individuals: IndividualInfo[] = [];

  formGroup: FormGroup<{
    individuals: KycVerificationIndividualsFormArray
  }>;
  formArray: KycVerificationIndividualsFormArray

  duplicateIndividualEmailValidator = duplicateIndividualEmailValidator;
  duplicateIndividualMobileValidator = duplicateIndividualMobileValidator;

  livenessPicture!: string;
  livenessBoundingBoxes: BoundingBoxData[] = [];
  driverLicence!: string;
  driverLicenceBoundingBoxes: BoundingBoxData[] = [];

  constructor(private formBuilder: FormBuilder,
              private hotToastService: HotToastService,
              private dialogService: ApplicationDialogService,
              private portalHotToastService: PortalHotToastService,
              private digitalIdService: DigitalIdService,
              private sanitizer: DomSanitizer) {
    this.formArray = formBuilder.array<KycVerificationIndividualFormGroup>([]);
    this.formGroup = formBuilder.group({
      individuals: this.formArray,
    });
  }

  ngOnInit() {
    setupUntilDestroy(this);
    const companyId = this.application.CompanyId;
    if (companyId) {
      const sub = this.getOriginatorByIdFn(companyId).pipe(
        this.portalHotToastService.topMenuLoadingObservable(),
        tap(r => {
          const o: OriginatorBusiness | null = r;
          if (o) {
            const relationship = o.Relationship;
            this.isBrokerDisclosed = !!(relationship && relationship.trim().toLowerCase() === 'disclosed');
          }
        })
      ).subscribe();
      this.subscriptions.push(sub);
    }
    this.refresh();
  }

  refresh() {
    this.alerts = [];
    this.formArray.clear();
    this.individuals = [];
    // this.digitalIdService.getApplicationIndividuals(this.application.ApplicationId)
    this.getApplicationIndividualsFn(this.application.ApplicationId)
      .pipe(
        this.portalHotToastService.topMenuLoadingObservable(),
        tap(r => {
          const individuals = r.individuals;
          console.log('**** digitalId refresh individuals', individuals);
          this.addIndividuals(individuals);
          this.application.Individuals = individuals;
        })
      ).subscribe();
  }

  addIndividuals(individuals: IndividualWithResult[]) {
    const individualInfo: IndividualInfo[] = [];
    for (const individual of individuals) {
      const formControlTitle: FormControl<TitleSelectionValue> = this.formBuilder.control(individualTitle(individual), [Validators.required]);
      const formControlFirstName: FormControl<NameComponentValue> = this.formBuilder.control(individual.GivenName, [Validators.required]);
      const formControlMiddleName: FormControl<string|null> = this.formBuilder.control(individual.MiddleName);
      const formControlLastName: FormControl<NameComponentValue> = this.formBuilder.control(individual.SurName, [Validators.required]);
      const formControlDob: FormControl<DatepickerValue> = this.formBuilder.control(individualDob(individual), [Validators.required]);
      const formControlResidentialAddress: FormControl<Address2ComponentValue> = this.formBuilder.control(individualResidentialAddress(individual), [Validators.required]);
      const formControlEmail: FormControl<EmailComponentValue> = this.formBuilder.control(individual.Email);
      const formControlMobile: FormControl<MobileValue> = this.formBuilder.control(individual.MobileNumber);
      const formControlSignerRole: FormControl<SignerRoleTypes> = this.formBuilder.control(individual.SignerRole);
      const formControlContact :  FormControl<string | null> = this.formBuilder.control(individual.Contact);
      const formArrayAdditionalRoles: FormArray<AdditionalRoleFormGroup> = this.formBuilder.array<AdditionalRoleFormGroup>([]);
      // additional roles form array
      for (const additionalRole of individual?.additionalRoles ?? []) {
        const additionalRoleForm = this.formBuilder.group({
          business: [additionalRole.business, Validators.required],
          signerRole: [additionalRole.signerRole, Validators.required]
        })
        formArrayAdditionalRoles.push(additionalRoleForm);
      }
      const formGroup: KycVerificationIndividualFormGroup = this.formBuilder.group({
        title: formControlTitle,
        firstName: formControlFirstName,
        middleName: formControlMiddleName,
        lastName: formControlLastName,
        dob: formControlDob,
        residentialAddress: formControlResidentialAddress,
        email: formControlEmail,
        mobile: formControlMobile,
        signerRole: formControlSignerRole,
        additionalRoles: formArrayAdditionalRoles,
        contact: formControlContact
      });
      (formGroup as any)['individualWithResult'] = individual;
      this.formArray.push(formGroup);
      individualInfo.push({
        formGroup, individual
      });
    }
    this.individuals = individualInfo;
    this.dataSource.update(individuals);
  }

  addNewIndividual() {
    this.dialogService.openIndividualFormDialog({
      existingIndividuals: this.individuals.map(i => i.individual),
      businessSearchFn: this.businessNumberSearchFn,
    }).afterClosed().pipe(
      tap((r?: IndividualFormDialogResult) => {
        if (r && r.valid && r.individual) {
          const i = r.individual;
          const add = i.residentialAddress ? parseRawAddress(i.residentialAddress.address) : null;
          this.addIndividualFn(this.application.ApplicationId, {
            Title: i.title?.type ?? '',
            SurName: i.lastName,
            GivenName: i.firstName,
            MiddleName: i.middleName,
            Role: i.role,
            SignerRole: i.signerRole,
            HomeAddress: {
              UnformattedAddress: i.residentialAddress?.address ?? '',
              UnitNumber: add ? add.unit_number ?? '' : '',
              Postcode: add ? add.postal_code : '',
              State: add ? add.region ? add.region : '' : '',
              Suburb: add ? add.locality : '',
              StreetName: add ? add.street_name : '',
              StreetNumber: add ? add.street_number : '',
              StreetType: add ? add.street_type ?? '' : '',
              IsManualInput: false,
            },
            DoB: i.dob.format('YYYY-MM-DD'),
            Email: i.email,
            MobileNumber: i.mobile,
            GuarantorFlag: i.guarantor ?? false,
            thirdPartyEntity: i.thirdPartyEntity,
            Contact: i.contact
          })
            .pipe(
              this.portalHotToastService.spinnerObservable(),
              this.portalHotToastService.snackBarObservable('Individual added'),
              tap(r => {
                this.refresh();
              })
            ).subscribe();
        }
      })
    ).subscribe();
  }

  runVerificationsOnAllIndividuals(){
    this.verifyApplicationIndividualsFn(this.application.ApplicationId, false)
      .pipe(
        this.portalHotToastService.spinnerObservable(),
        this.loader.allIndividualsDigitalIdCheck.track(),
        tap(r => {
          this.refresh();
        })
      ).subscribe();
  }

  // Check for unsaved changes before running verification
  _runVerificationOnIndividual(individual: IndividualWithResult) {
    const changes = this.getFormControlChanges(individual);
    const labels = this.generateChangeMessages(changes);
    console.log('labels', labels)
    if (labels && labels.length) {
      this.subscriptions.push(this.dialogService.openUnsavedChangesDialog(labels)
        .afterClosed()
        .pipe(
          tap(r => {
            console.log('result of unsaved changes', r)
            if (r) {
              this._saveIndividual(individual, this.runVerificationOnIndividual);
            }
            // else if (r === false) {
            //   this.runVerificationOnIndividual(individual);
            // }
          })
        ).subscribe());
    }
    else {
      this.runVerificationOnIndividual(individual);
    }
  }

  runVerificationOnIndividual = (individual: IndividualWithResult) => {
    if (!individual.id) {
      // NOTE: if it is not setup before (no individual id) run verification on all individuals (which will run setup first)
      this.runVerificationsOnAllIndividuals();
      // this.dialogService.openAlertDialog({
      //   message: 'Error',
      //   subMessage: `Individual ${individual.GivenName} ${individual.SurName} do not have an id, cannot run digital verification`
      // });
      return;
    }
    this.verifyOneApplicationIndividualFn(this.application.ApplicationId, individual.id)
      .pipe(
        this.portalHotToastService.spinnerObservable(),
        this.loader.singleIndividualDigitalIdCheck.track(),
        tap(r => {
          this.refresh();
        })
      ).subscribe();
  }


  // Check for unsaved changes before verifying
  _verifyNow(individual: IndividualWithResult) {
    const changes = this.getFormControlChanges(individual);
    const labels = this.generateChangeMessages(changes);
    console.log('labels', labels)
    if (labels && labels.length) {
      this.subscriptions.push(this.dialogService.openUnsavedChangesDialog(labels)
        .afterClosed()
        .pipe(
          tap(r => {
            console.log('result of unsaved changes', r)
            if (r) {
              this._saveIndividual(individual, this.verifyNow)
            }
          })
        ).subscribe());
    }
    else {
      this.verifyNow(individual);
    }
  }

  verifyNow = (individual: IndividualWithResult) => {
    if (!individual.id) {
      // NOTE: if it is not setup before (no individual id) run verification on all individuals (which will run setup first)
      this.runVerificationsOnAllIndividuals();
      // this.dialogService.openAlertDialog({
      //   message: 'Error',
      //   subMessage: `Individual ${individual.GivenName} ${individual.SurName} do not have an id, cannot run digital verification`
      // });
      return;
    }
    if ((!individual.result.isNameOrDobChanged) && individual.result.exp && Number(individual.result.exp) > Math.floor(Date.now() / 1000)) {
      this.subscriptions.push(this.dialogService.openDigitalIdVerifyDialog({
        individual,
        kycResult: individual.result,
        getClientFn: this.getClientFn,
        authenticateFn: this.authenticateFn,
        digitalIdOnCompleteCallbackFn: this.digitalIdOnCompleteCallbackFn,
      }).afterClosed().subscribe());
    }
    else {
      this.subscriptions.push(
        this.verifyOneApplicationIndividualFn(this.application.ApplicationId, individual.id)
          .pipe(
            this.portalHotToastService.spinnerObservable(),
            this.loader.singleIndividualDigitalIdCheck.track(),
          )
          .subscribe(
            (response: IndividualWithResult) => {
              console.log('=====kyc response: ', response)
              if (response.result.status === 'RECEIVED_COMPLETE') {
                this.refresh();
              } else {
                this.dialogService.openDigitalIdVerifyDialog({
                  individual,
                  kycResult: response.result,
                  getClientFn: this.getClientFn,
                  authenticateFn: this.authenticateFn,
                  digitalIdOnCompleteCallbackFn: this.digitalIdOnCompleteCallbackFn,
                })
                  .afterClosed()
                  .pipe(
                    tap(r => {
                      this.refresh();
                    })
                  ).subscribe();
              }
            }
          )
      )
    }

  }

  _sendVerificationEmailToIndividual(individual: IndividualWithResult) {
    const changes = this.getFormControlChanges(individual);
    const labels = this.generateChangeMessages(changes);
    console.log('changes', changes)
    console.log('labels', labels)
    if (labels && labels.length) {
      this.subscriptions.push(this.dialogService.openUnsavedChangesDialog(labels)
        .afterClosed()
        .pipe(
          tap(r => {
            console.log('result of unsaved changes', r)
            if (r) {
              this._saveIndividual(individual, this.sendVerificationEmailToIndividual)
            }
            // else if (r === false) {
            //   this.sendVerificationEmailToIndividual(individual);
            // }
          })
        ).subscribe());
    }
    else {
      this.sendVerificationEmailToIndividual(individual);
    }
  }

  sendVerificationEmailToIndividual = (individual: IndividualWithResult) => {
    if (!individual.id) {
      this.dialogService.openAlertDialog({
        message: 'Error',
        subMessage: `Individual ${individual.GivenName} ${individual.SurName} do not have an id, cannot send out email`
      });
      return;
    }
    if (!individual.Email) {
      this.dialogService.openAlertDialog({message: 'Error', subMessage: `Individual ${individual.GivenName} ${individual.SurName} do not have an email`});
      return;
    }
    // await this.spinner.show();
    // this.digitalIdService.sendAskForVerificationInfoEmail(this.application.ApplicationId, individual.id!)
    this.sendAskForVerificationInfoEmailFn(this.application.ApplicationId, individual.id!)
      .pipe(
        this.portalHotToastService.snackBarObservable(`Request Verification Email sent`)
      ).subscribe();
  }

  individualFormGroup(individual: IndividualWithResult) : KycVerificationIndividualFormGroup | undefined {
    const individualInfo = this.individuals.find(i => i?.individual?.id === individual.id);
    return individualInfo?.formGroup;
  }

  individualFormGroupControl(individual: IndividualWithResult, controlName: KycVerificationIndividualFormGroupKeys): KycVerificationPossibleFormControls {
    const individualInfo = this.individuals.find(i => i?.individual?.id === individual.id);
    const control = individualInfo?.formGroup?.controls[controlName];
    if (!control) {
      throw Error(`control ${controlName} is not found`);
    }
    return control;
  }

  individualAdditionalRolesFormArray(individual: IndividualWithResult): FormArray<AdditionalRoleFormGroup> {
    const individualInfo = this.individuals.find(i => i?.individual?.id === individual.id);
    const control = individualInfo?.formGroup?.controls.additionalRoles;
    if (!control) {
      throw Error(`additionalRoles is not found`);
    }
    return control;
  }

  getFormControlChanges(individual: IndividualWithResult) {
    const formControlToIndividualKeyMap: { [key in keyof KycVerificationIndividualFormGroup['controls']]: keyof Individual } = {
      title: 'Title',
      firstName: 'GivenName',
      middleName: 'MiddleName',
      lastName: 'SurName',
      dob: 'DoB',
      residentialAddress: 'HomeAddress',
      email: 'Email',
      mobile: 'MobileNumber',
      signerRole: 'SignerRole',
      additionalRoles: 'additionalRoles',
      contact: 'Contact'
    };
    const changes: { [key: string]: { original: any, current: any } } = {};

    const individualInfo = this.individuals.find(i => i?.individual?.id === individual.id);
    if (!individualInfo) {
      throw new Error(`Individual with id ${individual.id} not found`);
    }

    const formGroup = individualInfo.formGroup;
    const initialIndividual: Individual = individualInfo.individual;

    Object.keys(formGroup.controls).forEach(controlName => {
      const control = formGroup.get(controlName);
      let currentValue = control?.value ?? '';

      // Get the corresponding key in the initial individual object
      const individualKey = formControlToIndividualKeyMap[controlName as keyof KycVerificationIndividualFormGroup['controls']];
      const initialValue = initialIndividual[individualKey];

      // Handle nested structures, like residentialAddress
      let initialFormattedValue = initialValue ?? '';
      if (controlName === 'dob' && currentValue) {
        console.log('initialFormattedValue dob', currentValue)
        currentValue = (currentValue as any).format('YYYY-MM-DD'); // or other required format
      }
      // // Handle cases where the control value is a complex object
      else if (controlName === 'residentialAddress' && initialValue) {
        console.log('initialFormattedValue Address', initialValue)
        console.log('currentValue Address', currentValue)
        initialFormattedValue = (initialValue as Address).UnformattedAddress; // The interface of address is different in control and saved one
        currentValue = (currentValue as Address2ComponentValue)?.address;
      }
      else if (controlName === 'title' && currentValue) {
        console.log('currentValue title', currentValue)
        currentValue = (currentValue as any)?.type;
      }
      else if (controlName == 'additionalRoles') {
        currentValue = currentValue ? JSON.stringify(currentValue) : JSON.stringify([]);
        initialFormattedValue = initialFormattedValue ? JSON.stringify(initialFormattedValue) : JSON.stringify([]);
      }

      if (initialFormattedValue !== currentValue) {
        changes[controlName] = {
          original: initialFormattedValue,
          current: currentValue
        };
      }
    });

    return changes;
  }

  // Function to generate change messages
  generateChangeMessages(changes: { [key: string]: { original: any, current: any } }): string[] {
    const controlNameToUserFriendlyNameMap: { [key in keyof KycVerificationIndividualFormGroup['controls']]: string } = {
      title: 'Title',
      firstName: 'First Name',
      middleName: 'Middle Name',
      lastName: 'Last Name',
      dob: 'Date of Birth',
      residentialAddress: 'Residential Address',
      email: 'Email',
      mobile: 'Mobile Number',
      signerRole: 'Signer Role',
      additionalRoles: 'Additional Roles',
      contact:'Contact'
    };
    const messages: string[] = [];

    for (const controlName in changes) {
      if (Object.prototype.hasOwnProperty.call(changes, controlName)) {
        const change = changes[controlName];
        const userFriendlyName = controlNameToUserFriendlyNameMap[controlName as keyof KycVerificationIndividualFormGroup['controls']];

        if (controlName === 'additionalRoles') {
          messages.push(`${userFriendlyName} have been changed`);
        } else {
          messages.push(`${userFriendlyName} changed from '${change.original}' to '${change.current}'`);
        }
      }
    }

    return messages;
  }

  resetDigitalIDVerification (applicationId: number, individualId: string) {
    this.updateApplicationIndividualDigitalIdMappingFn(applicationId, individualId, {
      dataSourceEvents: [
        'credit_header_check_failed'
      ],
      transactionId: '',
      verificationSessionToken: undefined, // undefined as it will be overriden by verifyOneApplicationIndividualFn
      verificationStatus: 'in_progress',
    }).subscribe(() => {
      this.verifyOneApplicationIndividualFn(this.application.ApplicationId, individualId)
        .pipe(
          this.portalHotToastService.spinnerObservable(),
          this.loader.singleIndividualDigitalIdCheck.track(),
          tap(r => {
            this.refresh();
          })
        ).subscribe();
    });
  }

  _saveIndividual(individual: IndividualWithResult, callBackFn?: (ind: IndividualWithResult) => void) {
    // await this.spinner.show();
    console.log('saving individual', individual)
    const individualInfo = this.individuals
      .find( i => (i?.individual?.id === individual.id));

    const ind = individualInfo?.individual;

    if (!individualInfo || !ind || !ind.id) {
      this.dialogService.openAlertDialog({subMessage: `Unable to find individual ${individual.id ?? ''} to update`, message: `Unable to update`});
      return;
    } else if (this.isStatusOk(ind)) {
      const importantChanges = [
        "title",
        "firstName",
        "middleName",
        "lastName",
        "dob",
        "residentialAddress",
        // "email",
        // "mobile",
        // "signerRole",
        // "additionalRoles",
      ];
      const changes = this.getFormControlChanges(individual);
      let hasImportantChange = false;
      Object.keys(changes).forEach((change: string) => {
        if (importantChanges.includes(change)) {
          hasImportantChange = true;
        }
      });

      if (hasImportantChange) {
        this.dialogService.openDigitalIDReverificationConfirmationDialog()
            .afterClosed().subscribe((result: ConfirmationDialogResult | undefined) => {
              if (result?.readyForSubmission) {
                this.resetDigitalIDVerification(this.application.ApplicationId, ind.id!);
                this.saveIndividual(individualInfo, callBackFn);
              }
            });
      } else {
        this.saveIndividual(individualInfo, callBackFn);
      }
    } else {
      this.saveIndividual(individualInfo, callBackFn);
    }
  }


  saveIndividual(individualInfo: IndividualInfo, callBackFn?: (ind: IndividualWithResult) => void) {
    
    const ind = individualInfo?.individual;

    const addressValue = ((individualInfo.formGroup.controls as any).residentialAddress.value as Address2ComponentValue);
    const parsedAddress = addressValue ? parseRawAddress(addressValue.address) : null;
    const signerRole: SignerRoleTypes = (individualInfo.formGroup.controls as any).signerRole.value;
    let guarantor = ind.GuarantorFlag;
    if (signerRole != null && (signerRole == 'Guarantor' || signerRole == 'GuarantorSigner')) {
      // if signer role is selected
      guarantor = true;
    }
    const data = {
      title: ((individualInfo.formGroup.controls as any).title.value as TitleSelectionValue)?.type,
      firstName: ((individualInfo.formGroup.controls as any).firstName).value,
      lastName: ((individualInfo.formGroup.controls as any).lastName).value,
      middleName: ((individualInfo.formGroup.controls as any).middleName).value,
      birthdate: ((individualInfo.formGroup.controls as any).dob).value.format('YYYY-MM-DD'),
      country: 'Australia',
      signerRole: signerRole,
      email: ((individualInfo.formGroup.controls as any).email).value,
      mobileNumber: ((individualInfo.formGroup.controls as any).mobile).value,
      rawAddress: addressValue ? addressValue.address : '',
      unitNumber: parsedAddress ? parsedAddress.unit_number ?? '' : '',
      streetNumber: parsedAddress ? parsedAddress.street_number : '',
      streetName: parsedAddress ? parsedAddress.street_name : '',
      streetType: parsedAddress ? parsedAddress.street_type ?? '' : '',
      state: parsedAddress ? parsedAddress.region ? parsedAddress.region : '' : '',
      suburb: parsedAddress ? parsedAddress.locality : '',
      postcode: parsedAddress ? parsedAddress.postal_code : '',
      guarantor: guarantor,
      additionalRoles: ((individualInfo.formGroup.controls as any).additionalRoles).value.filter((v: IndividualAdditionalRole) => v.business),
      contact : ((individualInfo.formGroup.controls as any).contact).value,
    };

    if (data.additionalRoles.length > 0 && (data.email === "" || data.mobileNumber === "")) {
      this.dialogService.openAlertDialog({
        message: `Error`,
        subMessage: `Missing email or mobile number. Please enter and try again`,
      });
    } else {
      this.updateIndividualFn(this.application.ApplicationId, ind.id!, this.application.ApplicationType, data).pipe(
        this.portalHotToastService.spinnerObservable(),
        this.portalHotToastService.snackBarObservable('Save data'),
        tap(r => {
          if (callBackFn) {
            const updatedIndividual = { ...r, result: { ...ind.result, isNameOrDobChanged: r.isNameOrDobChanged } };
            callBackFn(updatedIndividual);
          }
          this.refresh();
        })
      ).subscribe();
    }
  }

  getVerificationSessionToken(individual: IndividualWithResult): string {
    const digitalIdResult = individual.result;
    switch (digitalIdResult.status) {
      case 'RECEIVED_INCOMPLETE': {
        return digitalIdResult.digitalIdResponse?.verification_session_token ?? '';
      }
    }
    return '';
  }

  verificationStatusDisplay(verificationStatus?: string): string {
    if (verificationStatus) {
      switch (verificationStatus) {
        case 'completed':
          return 'Completed';
        case 'in_progress':
          return 'In Progress';
        case 'failed':
          return 'Failed';
        default:
          return 'Not available';
      }
    }
    return 'Not available';
  }

  datasourceEventDisplay(event: string): string {
    if (event) {
      switch (event) {
        case 'credit_header_check_failed':
          return 'Credit header check failed';
        case 'name_and_address_matched':
          return 'Name and address matched';
        case 'name_and_dob_matched':
          return 'Name and DOB matched';
        default:
          return 'Not available';
      }
    }
    return 'Not available';
  }

  sourcesCategoryDisplay(sources_category: string): string {
    if (sources_category) {
      switch (sources_category) {
        case 'pep':
          return 'Pep';
        case 'sanctions':
          return 'Sanctions';
        case 'pep_and_sanctions':
          return 'Pep and Sanctions';
        case '':
          return 'Not available';
      }
    }
    return 'Not available';
  }

  capitalize(s: any): string {
    return s.toString() ? _.startCase(s.toString()) : s;
  }

  defaultString(s: any): string {
    return s ? s : 'Not available';
  }

  digitalIdOnCompleteCallbackFn = ($event: DigitalIdComponentEvent, individual: IndividualWithResult) => {
    console.log('**** digitalIdOnCompleteCallbackFn', $event, individual);
    switch ($event.authResult.type) {
      case 'error': {
        this.alerts = [];
        this.alerts.push({ type: 'warn', message: `${$event.authResult.error} - ${$event.authResult.errorDescription}`});
        break;
      }
      case 'success': {
        const rst = parseDigitalIdResponse($event);
        const applicationId = this.application.ApplicationId;
        const individualId = individual.id;
        concat(
          this.updateApplicationIndividualDigitalIdMappingFn(applicationId, individualId!, {
            dataSourceEvents: [
              'kyc_basic_callback_result_success'
            ],
            transactionId: $event.transactionId,
            verificationSessionToken: undefined,
            verificationStatus: 'completed',
          }).pipe(
            tap(r => {
              console.log('updated individualDigitalIdMapping', r);
            })
          ),
          this.updateApplicationIndividualInfoFn(applicationId, individualId!, rst).pipe(
            tap(r => {
              console.log(r);
              const verificationResult: DigitalIdResult = r.result;
              const indInfo = this.individuals.find(i => i.individual.id === individual.id);
              if (indInfo) {
                indInfo.individual.result = verificationResult;
              }
            }),
          ),
        ).subscribe();
        break;
      }
    }
  }

  printDigitalIdResult(individual: IndividualWithResult) {
    // this.digitalIdService.printDigitalIdResult(this.application.ApplicationId, individualInfo.individual.id!)
    this.printDigitalIdResultFn(this.application.ApplicationId, individual.id!)
      .pipe(
        tap( blob => {
          if (blob) {
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank')?.focus();
          } else {
            // this.hotToastService.error(`Unable to generate PDF result`);
          }
        })
      ).subscribe();
  }

  canActionDigitalVerification(individual: IndividualWithResult): boolean {
    const hasApplicationId = !!this.application.ApplicationId;
    const isIncomplete = individual?.result?.status === 'RECEIVED_INCOMPLETE';
    const loggedinUser = getUser();
    const isLoggedIn = !!loggedinUser;
    const hasAccess = loggedinUser?.AccessLevel === 'admin' || loggedinUser?.AccessLevel === 'internalbroker' || loggedinUser?.AccessLevel === 'salesBDM' || loggedinUser?.AccessLevel === 'salesAM';
    return (hasApplicationId && isIncomplete && isLoggedIn && hasAccess);
  }


  allowDigitalIdActions(): boolean {
    const user = getUser();
    const isLoggedIn = !!user;
    const hasAccess = !!user && (user.AccessLevel === 'admin' || user.AccessLevel === 'internalbroker'|| user?.AccessLevel === 'salesBDM' || user?.AccessLevel === 'salesAM');
    const status =  getApplicationStatus(this.application);
    let statusOk = false;
    switch (status) {
      case 'Declined':
      case 'Draft':
      case 'Withdrawn':
        statusOk = false;
        break;
      default:
        statusOk = true;
        break;
    }
    return (isLoggedIn && hasAccess && statusOk);
  }

  disableSubmission(element: IndividualWithResult): boolean {
    // NOTE: formGroup can be valid (no validators set for it)
    //       when email / mobile have eg duplication errors
    //       where we want to disable to submission button
    return this.individualFormGroup(element)?.invalid || ((this.individualFormGroup(element) as any).emailComponentError != null) || ((this.individualFormGroup(element) as any).mobileComponentError != null)
  }

  duplicatedEmailOrMobile(element: IndividualWithResult): boolean {
    return ((this.individualFormGroup(element) as any).emailComponentError != null) || ((this.individualFormGroup(element) as any).mobileComponentError != null)
  }

  disableSave(individualInfo: IndividualInfo) {
    return (individualInfo.formGroup.pristine || individualInfo.formGroup.invalid);
  }

  setupMapping() {
    // this.digitalIdService.setupApplicationIndividualMapping(this.application.ApplicationId).pipe(
    this.setupApplicationIndividualMappingFn(this.application.ApplicationId).pipe(
      tap(r => {
        this.refresh();
      })
    ).subscribe();
  }



  isTableRowExpanded(element: IndividualWithResult): boolean {
    return (this.expandedElement === element);
  }

  expandTableRow(element: IndividualWithResult) {
    console.log('****** row bubling');
    this.expandedElement = this.expandedElement === element ? null : element;

    if (this.expandedElement) {
      const result = element.identityVerificationResult;
      if (result) {
        if (result.driverLicenceFile) {
          this.driverLicenceBoundingBoxes = result.driverLicenceBoundingBox.map(data => data?.Face);
          this.downloadDocumentFromAzureFn(result.driverLicenceFile)
          .pipe(this.portalHotToastService.spinnerObservable())
          .subscribe(buffer => {
            const mimeType = mime.getType(result.driverLicenceFile);
            this.driverLicence = `data:${mimeType};base64,` + Buffer.from(buffer.data).toString('base64');
          })
        } else {
          this.driverLicence = '';
        }

        if (result.livenessPicture) {
          this.livenessBoundingBoxes = [result.livenessPictureBoundingBox];
          this.downloadDocumentFromAzureFn(result.livenessPicture)
          .pipe(this.portalHotToastService.spinnerObservable())
          .subscribe(buffer => {
            this.livenessPicture = 'data:image/png;base64,' + Buffer.from(buffer.data).toString('base64');
          })
        } else {
          this.livenessPicture = '';
        }
      }
    }
  }


  isStatusOk(element: IndividualWithResult) {
    return element?.result?.status == 'RECEIVED_COMPLETE';
  }

  isStatusIncomplete(element: IndividualWithResult) {
    return element?.result?.status == 'RECEIVED_INCOMPLETE';
  }

  isStatusError(element: IndividualWithResult) {
    return element?.result?.status == 'RECEIVED_ERROR';
  }

  isVerificationResultDetailsExpanded(element: IndividualWithResult) {
    return this.expandedResultElement == element;
  }
  expandVerificationResultDetails(element: IndividualWithResult) {
    this.expandedResultElement = this.expandedResultElement === element ? null : element;
  }

  deleteIndividual(event: Event, element: IndividualWithResult) {
    if (element.id) {
      if(element.Contact === 'PrimaryContact'){
        this.dialogService.openAlertDialog({
          message: `Info`,
          subMessage: `Unable to delete the primary individual. Please assign another individual as primary before proceeding with deletion.`,
        });
      }
      else{
      this.updateIndividualFn(this.application.ApplicationId, element.id, this.application.ApplicationType, {
        deleted: true,
      }).pipe(
        this.portalHotToastService.spinnerObservable(),
        this.loader.deleteIndividual.track(),
        this.portalHotToastService.snackBarObservable(`Individual deleted`),
        tap(r => {
          this.refresh();
        })
      ).subscribe();
      }
    } else {
      this.dialogService.openAlertDialog({
        message: `Info`,
        subMessage: `Missing individual id, please run validation and try again`,
      });
    }
    return false;
  }

  onPrimaryContact(element: IndividualWithResult) {
    this.digitalIdService.updatePrimaryContact(this.application.ApplicationId, element.id)
    .pipe(this.portalHotToastService.spinnerObservable(),
      this.portalHotToastService.snackBarObservable(`Individual updated`),
      tap(r => {
        if(r){
        this.refresh();
        }
      })
    ).subscribe();
}


  addAdditionalRole(element: IndividualWithResult) {
    const additionalSignerForm = this.formBuilder.group({
      business: [null as BusinessNumberSearchValue, Validators.required],
      signerRole: ["Guarantor" as SignerRoleTypes, Validators.required]
    })
    const individualInfo = this.individuals.find(i => i?.individual?.id === element.id);
    const formArray = individualInfo?.formGroup?.controls.additionalRoles;
    if (formArray) {
      formArray.push(additionalSignerForm);
    }
  }

  deleteAdditionalRole(element: IndividualWithResult, index: number) {
    const individualInfo = this.individuals.find(i => i?.individual?.id === element.id);
    const formArray = individualInfo?.formGroup?.controls.additionalRoles;
    if (formArray) {
      formArray.removeAt(index);
    }
  }

  onEmailEvent($event: EmailComponentEvent, element: IndividualWithResult) {
    // this.emailComponentErrors = $event.errors;
    (this.individualFormGroup(element) as any).emailComponentError = $event.errors
  }

  onMobileEvent($event: MobileComponentEvent, element: IndividualWithResult) {
    (this.individualFormGroup(element) as any).mobileComponentError = $event.errors
  }

  displayAdditionalRoles(element: IndividualWithResult) {
    if (element?.additionalRoles && element?.additionalRoles?.length) {
      const additionalRoles = element?.additionalRoles.map(role => {
        return (role?.business?.name ?? '');
      });
      return additionalRoles.join('\n');
    } else {
      return '';
    }
  }

  generateVerificationLink(element: IndividualWithResult) {
    this.subscriptions.push(
      this.dialogService.openIdentityVerificationLinkDialog({
        individualId: element?.id ?? '',
        applicationId: this.application.ApplicationId,
        idVerifyUrl: this.idVerifyUrl,
        name: `${element.GivenName} ${element.SurName}`,
        email: element.Email,
        sendIdVerifyLinkFn: this.sendIdVerifyLinkFn
      }).afterClosed().subscribe(

      )
    )
  }

  bypassFaceCompare(element: IndividualWithResult) {
    if (element.identityVerificationResult?.id) {
      this.subscriptions.push(
        this.bypassFaceCompareFn(element.identityVerificationResult?.id).pipe(
          this.portalHotToastService.spinnerObservable(),
          this.portalHotToastService.retryableMessage({
            successMessage: 'Successfully bypass the face compare',
            errorMessage: 'Failed to bypass face compare',
            retryFn: ()=> {
              this.bypassFaceCompareFn(element.identityVerificationResult?.id ?? 0)
            }
          })
        ).subscribe(() => {
          this.refresh()
        })
      )
    }
  }

  removeIdentityVerification(element: IndividualWithResult) {
    if (element.identityVerificationResult?.id) {
      this.subscriptions.push(
        this.deleteIdentityVerificationFn(element.identityVerificationResult?.id).pipe(
          this.portalHotToastService.spinnerObservable(),
          this.portalHotToastService.retryableMessage({
            successMessage: 'Successfully remove the ID Verify record',
            errorMessage: 'Failed to remove the ID Verify record',
            retryFn: ()=> {
              this.deleteIdentityVerificationFn(element.identityVerificationResult?.id ?? 0)
            }
          })
        ).subscribe(() => {
          this.refresh()
        })
      )
    }
  }

  onSendPrivacyConsent(element: IndividualWithResult) {
    this.subscriptions.push(
      this.sendPrivacyConsentEmailFn(
        element,
        this.application.ApplicationId,
        this.application.ApplicationType === 'Consumer' ?
          `${applicationToApplicantFirstName(this.application)} ${applicationToApplicantLastName(this.application)}` :
          this.application.CommercialEntities.find(entity => entity.Type === 'Primary')?.LegalName ?? ''
      ).pipe(
        this.portalHotToastService.spinnerObservable(),
        this.portalHotToastService.retryableMessage({
          successMessage: 'Successfully sent the privacy consent',
          errorMessage: 'Failed to send the privacy consent',
          retryFn: ()=> {
            this.deleteIdentityVerificationFn(element.identityVerificationResult?.id ?? 0)
          }
        })
      ).subscribe(() => {
        this.refresh()
      })
    )
  }
}
