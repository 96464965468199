<div class="insurance-premium-summary-component">
  @if (summary) {
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="mat-h2">Summary</div>
        </div>
      </div>
      @if (summary.monthlyRepayment) {
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <mat-form-field>
              <mat-label>Monthly repayments</mat-label>
              <input matInput class="ml-4" type="input" readonly name="monthlyRepayment" [ngModel]="summary.monthlyRepayment">
              <span class="mdi mdi-currency-usd" matPrefix></span>
            </mat-form-field>
          </div>
        </div>
      }
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Application ID</div>
            <div body>{{summary.applicationId}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Organisation</div>
            <div body>{{summary.organisation?.organisationName}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Broker Contact</div>
            <div body>{{summary.brokerContact?.FirstName}} {{summary.brokerContact?.LastName}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>ABN</div>
            <div body>{{summary.abn}}</div>
          </cell>
        </div>
        @if (summary.organisationType?.name=='Company') {
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>ACN</div>
              <div body>{{summary.acn}}</div>
            </cell>
          </div>
        }
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        @if (summary.organisationType) {
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Organisation type</div>
              <div body>{{summary.organisationType.name}}</div>
            </cell>
          </div>
        }
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Revenue</div>
            <div body><span class="mdi mdi-currency-usd"></span>{{summary.annualRevenue}}</div>
          </cell>
        </div>
        <div clas="col-lg-3 col-md-12">
          <cell>
            <div label>Commercial premises</div>
            <div body>{{booleanToYesNo(summary.operateInCommercialPremises)}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <cell>
            <div label>Primary industry</div>
            <div body>{{summary.primaryIndustry?.name}}</div>
          </cell>
        </div>
        @if (summary.industrySector?.name) {
          <div class="col-lg-6 col-md-12">
            <cell>
              <div label>Industry sector</div>
              <div body>{{summary.industrySector?.name}}</div>
            </cell>
          </div>
        }
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <cell>
            <div label>Primary business address</div>
            <div body>{{summary.primaryBusinessAddress?.address}}</div>
          </cell>
        </div>
        @if (summary.businessLandline) {
          <div class="col-lg-6 col-md-12">
            <cell>
              <div label>Business landline</div>
              <div body>+61 {{summary.businessLandline}}</div>
            </cell>
          </div>
        }
      </div>
      <mat-divider [inset]="true"></mat-divider>
      @if (summary.policies && summary.policies.policies && summary.policies.policies.length) {
        @for (policy of summary.policies.policies; track policy; let index = $index) {
          <div class="row">
            <div class="col-12 mt-3">
              <div class="mat-h3">Policy {{index + 1}}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <cell>
                <div label>Type of cover</div>
                <div body>{{policy.coverType?.ProductName}}</div>
              </cell>
            </div>
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Policy number</div>
                <div body>{{policy.policyNumber}}</div>
              </cell>
            </div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <cell>
                <div label>Insurer</div>
                <div body>{{policy.insurer}}</div>
              </cell>
            </div>
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Inception date</div>
                <div body>{{displayableDate(policy.inceptionDate)}}</div>
              </cell>
            </div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Expiry date</div>
                <div body>{{displayableDate(policy.expiryDate)}}</div>
              </cell>
            </div>
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Premium amount</div>
                <div body>{{policy.premiumAmount}}</div>
              </cell>
            </div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
        }
      }
      <!-- DIRECTOR -->
      <div class="mt-3">
        <directors-summary [directors]="_directors"></directors-summary>
      </div>
      <!-- TRUSTEE -->
      <div class="mt-3">
        <trustees-summary [trustees]="_trustee"></trustees-summary>
      </div>
      <!-- PARTNER -->
      <div class="mt-3">
        <partners-summary [partners]="_partners"></partners-summary>
      </div>
      <!-- MEMBER -->
      <div class="mt-3">
        <members-summary [members]="_members"></members-summary>
      </div>
      <!-- SOLE TRADER -->
      <div class="mt-3">
        <soletraders-summary [soletraders]="_soleTraders"></soletraders-summary>
      </div>
      @if (summary.authorisedSignatories && summary.authorisedSignatories.length) {
        @for (signatory of summary.authorisedSignatories; track signatory; let index = $index) {
          <div class="row">
            <div class="col-12 mt-3">
              <div class="mat-h3">Authorised signatory {{index + 1}}</div>
            </div>
          </div>
          <div class="row">
            @if (signatory.title) {
              <div class="col-lg-3 col-md-12">
                <cell>
                  <div label>Title</div>
                  <div body>{{signatory.title.name}}</div>
                </cell>
              </div>
            }
            @if (signatory.firstName) {
              <div class="col-lg-3 col-md-12">
                <cell>
                  <div label>First name</div>
                  <div body><p [innerHTML]="signatory.firstName | ellipsis:10"></p></div>
                </cell>
              </div>
            }
            @if (signatory.lastName) {
              <div class="col-lg-3 col-md-12">
                <cell>
                  <div label>Last name</div>
                  <div body><p [innerHTML]="signatory.lastName | ellipsis:10"></p></div>
                </cell>
              </div>
            }
          </div>
          <mat-divider [inset]="true"></mat-divider>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <cell>
                <div label>Role</div>
                <div body>{{signatory.role?.name}}</div>
              </cell>
            </div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Email</div>
                <div body>{{signatory.email}}</div>
              </cell>
            </div>
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Mobile Number</div>
                <div body>{{signatory.mobile}}</div>
              </cell>
            </div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
        }
      }
       <!-- PRIMARY CONTACT -->
      <div class="mt-3">
        <primary-contact-summary [primaryContact]="summary.primaryContact"></primary-contact-summary>
      </div>
      @if (summary.applicationNotes) {
        <div class="row">
          <div class="col-12 mt-3">
            <div class="mat-h3">Application notes</div>
          </div>
        </div>
      }
      <div class="row">
        <div class="col-6">
          {{summary.applicationNotes}}
        </div>
      </div>
    </div>
  }
</div>
