import {Component, Input, OnInit} from '@angular/core';
import {Application, Contact, Individual} from '@portal-workspace/grow-shared-library';
import { MatDividerModule } from '@angular/material/divider';
import { CellComponent } from '../application-summary-component/cell.component';
import { FlexModule } from '@angular/flex-layout/flex';

import { EllipsisPipe } from '../../pipes/ellipsis.pipe';


@Component({
    selector: 'primary-contact-details',
    templateUrl: './primary-contact-details.component.html',
    styleUrls: ['./primary-contact-details.component.scss'],
    standalone: true,
    imports: [FlexModule, CellComponent, MatDividerModule, EllipsisPipe]
})
export class PrimaryContactDetailsComponent implements OnInit {

  @Input({required: true}) application!: Application;
  contact: Contact | null = null;

  ngOnInit(): void {
    this.contact = this.application.Contacts
  }

}
