import { Component, Inject, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import {
  ApiResponse,
  CustomerAccessLevelAndRoleAccessValue,
  DatepickerValue,
  NameComponentValue,
  PismoEditAccountDialogResult,
  PismoEditCustomerDialogData,
  PismoEditCustomerDialogResult,
  PismoUpdateCustomerFn,
  PismoUpdateCustomerResponse
} from '@portal-workspace/grow-shared-library';
import { Subscription, tap } from 'rxjs';
import moment from 'moment';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { DatepickerComponent } from '../datepicker-component/datepicker.component';
import { createMobilePhoneNumberInputMask } from '../component-utils';
import { InputMaskModule } from '@ngneat/input-mask';
import { NameComponent } from '../name-component/name.component';
import { MobileComponent } from '../mobile-component/mobile.component';
import {CustomerAccessLevelAndRoleAccessComponent } from '../access-level-component/customer-access-level-and-role-access.component';

@Component({
  templateUrl: './pismo-edit-customer.dialog.html',
  styleUrls: ['./pismo-edit-customer.dialog.scss'],
  standalone: true,
  imports: [
    FlexModule,DatepickerComponent,InputMaskModule, MatButtonModule, MatDialogModule, ReactiveFormsModule, 
    FormsModule, MatInputModule, NameComponent, MobileComponent,CustomerAccessLevelAndRoleAccessComponent
  ]
})
export class PismoEditCustomerDialog implements OnInit {

  pismoUpdateCustomer!: PismoUpdateCustomerFn;
  subscriptions: Subscription[] = [];
  createMobilePhoneNumberInputMask = createMobilePhoneNumberInputMask();
  formGroup: FormGroup<{
    firstName: FormControl<string | null | undefined>,
    middleName: FormControl<string | null | undefined>,
    lastName: FormControl<string | null | undefined>,
    dob: FormControl<DatepickerValue>,
    mobileNumber: FormControl<string | null | undefined>
    accessLevel: FormControl<CustomerAccessLevelAndRoleAccessValue>,
  }>;
  formControlFirstName: FormControl<string | null | undefined>;
  formControlMiddleName: FormControl<string | null | undefined>;
  formControlLastName: FormControl<string | null | undefined>;
  formControldob: FormControl<DatepickerValue>;
  formControlmobileNumber: FormControl<string | null | undefined>;
  formControlAccessLevel!: FormControl<CustomerAccessLevelAndRoleAccessValue>;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: PismoEditCustomerDialogData,
    private dialogRef: MatDialogRef<PismoEditCustomerDialog, PismoEditCustomerDialogResult>,
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
  ) {

    this.pismoUpdateCustomer = data.pismoUpdateCustomer;
    let accessLevel = null
    if(data.item && data.item.customerAccessLevel && data.item.RoleAccess){
      accessLevel = {
        customerAccessLevel : data.item.customerAccessLevel,
        roleAccess : data.item.RoleAccess
      }
    }
    this.formControlFirstName = formBuilder.control(data.item ? data.item.GivenName : null, [Validators.required]);
    this.formControlMiddleName = formBuilder.control(data.item ? data.item.MiddleName : null);
    this.formControlLastName = formBuilder.control(data.item ? data.item.FamilyName : null, [Validators.required]);
    this.formControldob = formBuilder.control(data.item ?  moment(data.item.dob, 'YYYY-MM-DD') : null, [Validators.required]);
    this.formControlmobileNumber = formBuilder.control(data.item ? data.item.MobileNumber : null, [Validators.required]);
    this.formControlAccessLevel = formBuilder.control(accessLevel, [Validators.required]);

    this.formGroup = formBuilder.group({
      firstName: this.formControlFirstName,
      middleName: this.formControlMiddleName,
      lastName: this.formControlLastName,
      dob: this.formControldob,
      mobileNumber: this.formControlmobileNumber,
      accessLevel: this.formControlAccessLevel
    })
  }

  onCancel(event: Event) {
    this.dialogRef.close(); // if we close it without params, result will be undefined
  }

  onSave() {
    const body = {
      firstName: this.formControlFirstName.value!,
      middleName: this.formControlMiddleName.value!,
      lastName: this.formControlLastName.value!,
      dob: moment(this.formControldob.value).format('YYYY-MM-DD'),
      mobileNumber: this.formControlmobileNumber.value!,
      accessLevel: this.formControlAccessLevel.value
    }
    this.subscriptions.push(
      this.pismoUpdateCustomer(this.data.item.userId, body, this.data.item.pismoAccountNumber)
        .pipe(
            this.toastService.spinnerObservable(),
            this.toastService.retryableMessage({
              successMessage: 'Customer details updated',
              errorMessage: 'Failed to update the Customer',
              retryFn: ()=> {
                this.onSave();
              }
            })
          ).subscribe((response: PismoUpdateCustomerResponse) => {
            if(response){
              this.dialogRef.close({
                valid: true,
              });
            }
          })
        )
  }

  ngOnInit() {
    console.log("data.item ?",this.data.item)
  }
}


