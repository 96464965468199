<div class="commercial-finance-summary-component">
  @if (summary) {
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="mat-h2">Summary</div>
        </div>
      </div>
      @if (summary.monthlyRepayment || summary.estimatedDrawDownDate) {
        <div class="row">
          <div class="col-3">
            <mat-form-field>
              <mat-label>Monthly repayments</mat-label>
              <input matInput class="ml-4" type="input" readonly name="monthlyRepayment" [ngModel]="summary.monthlyRepayment">
              <span class="mdi mdi-currency-usd" matPrefix></span>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field>
              <mat-label>Estimated draw down date</mat-label>
              <input matInput type="input" readonly name="estimatedDrawDownDate" [ngModel]="displayableDate(summary.estimatedDrawDownDate)">
            </mat-form-field>
          </div>
        </div>
      }
      <div class="row">
        <div class="col-3">
          <cell>
            <div label>Application ID</div>
            <div body>{{summary.applicationId}}</div>
          </cell>
        </div>
        <div class="col-3">
          <cell>
            <div label>Organisation</div>
            <div body>{{summary.organisation?.organisationName}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-3">
          <cell>
            <div label>ABN</div>
            <div body>{{summary.abn}}</div>
          </cell>
        </div>
        @if (summary.organisationType?.name=='Company') {
          <div class="col-3">
            <cell>
              <div label>ACN</div>
              <div body>{{summary.acn}}</div>
            </cell>
          </div>
        }
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        @if (summary.organisationType) {
          <div class="col-3">
            <cell>
              <div label>Organisation type</div>
              <div body>{{summary.organisationType.name}}</div>
            </cell>
          </div>
        }
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-3">
          <cell>
            <div label>Revenue</div>
            <div body>{{summary.annualRevenue}}</div>
          </cell>
        </div>
        <div class="col-3">
          <cell>
            <div label>Commercial premises</div>
            <div body>{{booleanToYesNo(summary.operatesInCommercialPremises)}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        @if (summary.primaryIndustry) {
          <div class="col-6">
            <cell>
              <div label>Primary industry</div>
              <div body>{{summary.primaryIndustry.name}}</div>
            </cell>
          </div>
        }
        @if (summary.industrySector) {
          <div class="col-3">
            <cell>
              <div label>Industry sector</div>
              <div body>{{summary.industrySector.name}}</div>
            </cell>
          </div>
        }
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        @if (summary.address) {
          <div class="col-6">
            <cell>
              <div label>Primary business address</div>
              <div body>{{summary.address.address}}</div>
            </cell>
          </div>
        }
        <div class="col-3">
          <cell>
            <div label>Business landline</div>
            <div body>{{summary.businessLandline}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-3">
          <cell>
            <div label>Broker</div>
            <div body>{{summary.broker?.entityName}}</div>
          </cell>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <cell>
            <div label>Asset type</div>
            <div body>{{summary.assetCategoryType?.value}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        @if (summary.invoiceAmount) {
          <div class="col-3">
            <cell>
              <div label>Invoice amount</div>
              <div body>{{summary.invoiceAmount}}</div>
            </cell>
          </div>
        }
        @if (summary.loanTerms) {
          <div class="col-3">
            <cell>
              <div label>Loan term (in months)</div>
              <div body>{{summary.loanTerms.name}}</div>
            </cell>
          </div>
        }
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <!-- DIRECTOR -->
      <directors-summary [directors]="_directors"></directors-summary>
      <!-- TRUSTEE -->
      <trustees-summary [trustees]="_trustee"></trustees-summary>
      <!-- PARTNER -->
      <partners-summary [partners]="_partners"></partners-summary>
      <!-- MEMBER -->
      <members-summary [members]="_members"></members-summary>
      <!-- SOLE TRADER -->
      <soletraders-summary [soletraders]="_soleTraders"></soletraders-summary>
      <!-- GUARANTOR -->
      <guarantors-summary [guarantors]="summary.guarantors"></guarantors-summary>
      <!-- PRIMARY CONTACT -->
      <primary-contact-summary [primaryContact]="summary.primaryContact"></primary-contact-summary>
      <div class="row">
        <div class="col mt-3">
          @if (summary.applicationNotes) {
            <div class="mat-h3">Application notes</div>
          }
        </div>
      </div>
      <div class="row">
        <div class="col-6" >
          {{summary.applicationNotes}}
        </div>
      </div>
    </div>
  }
</div>
